@charset "utf-8";

body.dark {
    background-color: #111319;
    color: #a9afc3;
}
body.light {
    background-color: #ffffff;
}

body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5 {
    color: #fff;
}
body.dark .tm_topbar ul li a {
    color: #a9afc3;
}
body.dark .tm_topbar ul li.current a {
    color: #802a50;
}
body.dark .tm_topbar ul li a:hover {
    color: #802a50;
}
body.dark .tm_topbar ul li:last-child.current a {
    color: #802a50;
    border-color: #802a50;
}
body.dark .tm_topbar.animate .in {
    background-color: #111319;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.08);
}
body.dark .tm_hero .content .job {
    color: #fff;
}
body.dark .tm_hero .social ul li a {
    color: #a9afc3;
}
body.dark .tm_hero .social ul li a:hover {
    color: #802a50;
}
body.dark .tm_hero .content .name {
    color: #fff;
}
body.dark .tm_hero .mobile_social ul li a {
    color: #fff;
}
body.dark .tm_hero .mobile_social ul li a:hover {
    color: #802a50;
}
body.dark .tm_button a {
    color: #fff;
    border-color: #a9afc3;
}
body.dark .tm_button a:hover {
    color: #802a50;
    border-color: #802a50;
}
body.dark .tm_button.color a:hover {
    color: #fff;
}
body.dark .tm_topbar ul li:last-child a {
    color: #fff;
    border-color: #a9afc3;
}
body.dark .tm_topbar ul li:last-child a:hover {
    color: #802a50;
    border-color: #802a50;
}
body.dark .tm_about {
    background-color: #191c26;
}
body.dark .tm_about .short h3 {
    color: #fff;
}
body.dark .tm_about .extra .title {
    color: #fff;
}
body.dark .tm_about .list ul li p {
    color: #fff;
}
body.dark .tm_about .short {
    border-bottom-color: #323232;
}
body.dark .tm_about .list ul li p span {
    color: #a9afc3;
}
body.dark .tm_button.color a {
    border-color: #802a50;
}
body.dark .tm_resume .main_title h3 {
    color: #fff;
}
body.dark .tm_resume .resume_title span {
    color: #fff;
}
body.dark .tm_resume .resume_title span:before {
    background-color: #323232;
}
body.dark .progress_inner .background {
    background-color: #30333e;
}
body.dark .tm_resume .edu_list ul li p span {
    color: #fff;
}
body.dark .tm_resume .edu_list ul li p {
    color: #a9afc3;
}
body.dark .progress_inner span.label {
    color: #fff;
}

body.dark .tm_services ul li .list_inner {
    background-color: #191c26;
}
body.dark .tm_services ul li .list_inner:hover {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1);
}
body.dark .tm_services ul li h3 {
    color: #fff;
}
body.dark .tm_services ul li .learn_more {
    color: #a9afc3;
}
body.dark .tm_portfolio {
    background-color: #191c26;
}
body.dark .tm_testimonials .item .list_inner {
    background-color: #111319;
}
body.dark .tm_testimonials .item .right .details h3 {
    color: #fff;
}
body.dark .tm_testimonials .left_nav,
body.dark .tm_testimonials .right_nav {
    border-color: #a9afc3;
}
body.dark .tm_testimonials .left_nav:hover,
body.dark .tm_testimonials .right_nav:hover {
    border-color: #802a50;
}
body.dark .tm_testimonials .icon {
    background-color: #a9afc3;
}
body.dark .tm_testimonials .icon:before {
    background-color: #a9afc3;
}
body.dark .tm_testimonials .icon:after {
    background-color: #a9afc3;
}
body.dark .tm_news {
    background-color: #191c26;
}
body.dark .tm_news ul li .details span a {
    color: #a9afc3;
}
body.dark .tm_news ul li .details span a:before {
    color: #a9afc3;
}
body.dark .tm_news ul li .details span a:hover {
    color: #802a50;
}
body.dark .tm_news ul li .details .title {
    color: #fff;
}
body.dark .tm_news ul li .details .title:hover {
    color: #802a50;
}
body.dark .tm_modalbox_news .box_inner {
    background-color: #191c26;
}
body.dark .tm_modalbox_news .news_share span {
    color: #fff;
}
body.dark .tm_modalbox_news .news_share ul li a {
    color: #fff;
}
body.dark .tm_modalbox_news .news_share ul li a:hover {
    color: #802a50;
}
body.dark .tm_modalbox_news .main_content .quotebox p {
    color: #cdcdcd;
}
body.dark .tm_modalbox_service .description_wrap::-webkit-scrollbar,
body.dark .tm_modalbox_news .description_wrap::-webkit-scrollbar {
    width: 11px;
}
body.dark .tm_modalbox_service .description_wrap,
body.dark .tm_modalbox_news .description_wrap {
    scrollbar-width: thin;
    scrollbar-color: #333 #111;
}
body.dark .tm_modalbox_service .description_wrap::-webkit-scrollbar-track,
body.dark .tm_modalbox_news .description_wrap::-webkit-scrollbar-track {
    background: #111;
}
body.dark .tm_modalbox_service .description_wrap::-webkit-scrollbar-thumb,
body.dark .tm_modalbox_news .description_wrap::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 6px;
    border: 3px solid #111;
}
body.dark .tm_modalbox_service .box_inner {
    background-color: #191c26;
}
body.dark .tm_modalbox_service .popup_informations h3 {
    color: #fff;
}
body.dark .tm_contact .right .title span {
    color: #fff;
}
body.dark::-webkit-scrollbar {
    width: 11px;
}
body.dark {
    scrollbar-width: thin;
    scrollbar-color: #bbb #fff;
}
body.dark::-webkit-scrollbar-track {
    background: #fff;
}
body.dark::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 6px;
    border: 3px solid #fff;
}
body.dark .tm_mobile_menu .mobile_menu_inner {
    background-color: #191c26;
}
body.dark .tm_mobile_menu .dropdown {
    background-color: #191c26;
}
body.dark .tm_mobile_menu .dropdown .dropdown_inner ul li a {
    color: #fff;
}
body.dark .tm_mobile_menu .mobile_menu_inner {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.08);
}
body.dark .tm_mobile_menu .dropdown {
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.08);
}
body.dark .my_trigger .hamburger .hamburger-inner::before,
body.dark .my_trigger .hamburger .hamburger-inner::after,
body.dark .my_trigger .hamburger .hamburger-inner {
    background-color: #fff;
}
body.dark .tm_contact input {
    border-bottom-color: rgba(255, 255, 255, 0.3);
    color: #a9afc3;
}
body.dark .tm_contact textarea {
    border-bottom-color: rgba(255, 255, 255, 0.3);
    color: #a9afc3;
}
body.dark .tm_contact input:focus {
    border-bottom-color: rgba(255, 255, 255, 0.5);
}
body.dark .tm_contact textarea:focus {
    border-bottom-color: rgba(255, 255, 255, 0.5);
}
body.dark .tm_contact .short_list ul li a {
    color: #a9afc3;
}

body.dark .tm_contact .short_list ul li a:hover {
    color: #802a50;
}

body.dark {
    .resume-box-01,
    .tm_resume .resume_inner .skills,
    .tm_resume .education {
        background: #111319;
        border: none;
        box-shadow: none;
    }
    .resume-box-01 .hr {
        border-color: #323232;
    }
    .resume-box-01 h4 label,
    .skills h4 label,
    .edu_list h4 label {
        background: #323232;
        font-weight: normal;
    }
    .resume-box-01 h4 label {
        font-weight: 600;
    }
    .tm_resume .edu_list ul li:not(:last-child) {
        border-bottom-color: #323232;
    }
    .resume-box-01 h6 {
        color: #efefef;
    }
    .tab_list {
        li {
            color: #a9afc3;
            &.react-tabs__tab--selected {
                color: #fff;
            }
        }
    }
    .tm_testimonials .slick-arrow:after {
        filter: invert(1);
    }
    .tm_resume .edu_list ul li .year,
    .progress_inner span.number {
        color: #a9afc3;
    }
    .tm_news ul li .details .title {
        color: #fff;
        font-weight: 600;
    }
    .news_share ul li svg {
        color: #dedede;
    }
    .mobile-menu-wrapper .mobile_menu-icon {
        background: #000;
    }
    .mobile-menu-wrapper .mobile_menu-icon li a {
        color: #8a8a8a;
    }
}
