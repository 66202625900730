.tm_modalbox_service {
    .close {
        position: fixed;
        left: 100%;
        top: 0px;
        margin-left: 40px;
        z-index: 111111;
        a {
            text-decoration: none;
            color: #fff;
        }
        .svg {
            width: 50px;
            height: 50px;
        }
    }
    .description_wrap {
        position: relative;
        width: 100%;
        height: 100%;
        float: left;
        overflow: hidden;
        padding: 50px;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #999 #fff;
        .svg {
            display: none;
        }
        .service_title {
            display: none;
        }
        .learn_more {
            display: none;
        }
        &::-webkit-scrollbar {
            width: 11px;
        }
        &:-webkit-scrollbar-track {
            background: #fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #999;
            border-radius: 6px;
            border: 3px solid #fff;
        }
    }
    .popup_informations {
        width: 100%;
        height: auto;
        clear: both;
        float: left;
        .image {
            position: relative;
            max-height: 350px;
            margin-bottom: 50px;
            img {
                position: relative;
                min-width: 100%;
                opacity: 0;
            }
            .main {
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                background-repeat: no-repeat;
                // background-position: center;
                background-size: cover;
            }
        }
        h2 {
            margin: 0.5em 0;
        }
        h3 {
            color: #0f172a;
            font-size: 23px;
            font-weight: 700;
            margin: 1em 0;
        }
        p {
            margin-bottom: 30px;
        }

        ul {
            margin-left: 24px;
        }
    }
    .in_list {
        width: 100%;
        float: left;
        margin-bottom: 30px;
        padding-left: 20px;
        ul {
            margin: 0px;
            list-style-type: none;
            li {
                margin: 0px 0px 15px 0px;
                width: 100%;
                float: left;
                position: relative;
                padding-left: 20px;
                &:before {
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    background-color: #6e6e6e;
                    left: 0px;
                    top: 13px;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
                p {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.tm_services {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    padding-bottom: 90px;
    padding-top: 117px;
    background-color: #f9f9fc;

    .popup_informations {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: -11;
        display: none;
    }

    .service_list {
        width: 100%;
        height: auto;
        clear: both;
        float: left;
        padding-top: 60px;
    }
    ul {
        margin: 0px 0px 0px -30px;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        li {
            margin: 0px 0px 30px 0px;
            width: 25%;
            height: 300px;
            padding-left: 30px;
            float: left;
            .list_inner {
                width: 100%;
                height: 100%;
                clear: both;
                float: left;
                background-color: #fefefe;
                padding: 30px;
                border-radius: 6px;
                transition: all 0.3s ease;
                display: flex;
                flex-direction: column;
                cursor: pointer;

                .service_title {
                    flex: 1;
                }

                &:hover {
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    .learn_more {
                        color: #802a50;

                        &::after,
                        &::before {
                            background-color: #802a50;
                        }
                    }
                    .learn_more span {
                        width: 30px;
                        background-color: #802a50;
                    }
                }
            }
            .svg {
                width: 50px;
                height: 50px;
                color: #802a50;
                margin-bottom: 25px;
                fill: none;
            }
            h3 {
                font-weight: 600;
                font-size: 20px;
                color: #0f172a;
                margin-bottom: 20px;
            }
            .learn_more {
                text-decoration: none;
                color: #6e6e6e;
                font-size: 18px;
                display: inline-block;
                position: relative;
                bottom: 0;

                transition: all 0.3s ease;
                span {
                    position: absolute;
                    display: inline-block;
                    width: 10px;
                    height: 2px;
                    background-color: #6e6e6e;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: 14px;
                    border-radius: 50px;

                    transition: all 0.3s ease;

                    &::before {
                        position: absolute;
                        content: "";
                        width: 6px;
                        height: 2px;
                        background-color: #6e6e6e;
                        transform: rotate(45deg);
                        right: 0px;
                        top: -2px;
                        border-radius: 50px;

                        transition: all 0.3s ease;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 6px;
                        height: 2px;
                        background-color: #6e6e6e;
                        transform: rotate(-45deg);
                        right: 0px;
                        bottom: -2px;
                        border-radius: 50px;

                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .tm_modalbox_service {
        .close {
            margin-left: 20px;
        }
    }
}

@media (max-width: 1040px) {
    .tm_services {
        ul {
            li {
                width: 50%;
            }
        }
    }

    .tm_modalbox_service {
        h2 {
            font-size: 24px;
        }
    }
}
@media (max-width: 767px) {
    .tm_modalbox_service {
        .description_wrap {
            padding: 30px;
        }
        .close {
            .svg {
                width: 20px;
                height: 20px;
            }
            margin-left: 0px;
        }
    }
    .tm_services {
        padding-bottom: 50px;
        padding-top: 80px;

        ul {
            margin: 0px;
            li {
                width: 100%;
                padding-left: 0px;
                .list_inner {
                    padding: 30px;
                }
            }
        }
    }
}
